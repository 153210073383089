import styled from '@emotion/styled'
import { Close } from 'app/components/Common/Close'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Gallery } from 'app/components/GalleryCategories/Gallery'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import useLockedBody from 'app/utils/useLockedBody'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import { uniqBy } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Filter } from './Filter'

export interface CategoryProps {
  thumbnails?: ImageProps[]
  title: string
}

export interface Props {
  categories: CategoryProps[]
  languageCode: string
  siteName?: string
  title?: string
  subtitle?: string
}

export const GalleryCategories = memo(function GalleryCategories({
  categories,
  languageCode,
  siteName,
  title,
  subtitle,
}: Props) {
  if (!categories) {
    return null
  }

  const [_locked, setLocked] = useLockedBody()
  const [filteredCategory, setFilteredCategory] = useState('all')
  const [modalGalleryStatus, setModalGalleryStatus] = useState(false)
  const [clickedSlide, setClickedSlide] = useState<any>(null)

  let counter = 0
  let images: any = []

  const categoriesImages = categories.map((item: any) =>
    item.images.map((e: any) => images.push(e)),
  )

  useEffect(() => {
    if (modalGalleryStatus) {
      document.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
          case 27:
            setLocked(false)
            setModalGalleryStatus(false)
            setClickedSlide(0)
            break
        }
      })
    }
  }, [modalGalleryStatus])

  return (
    <Container>
      <Wrapper>
        <Top>
          {title ? <Heading>{title}</Heading> : null}
          {subtitle ? <Title>{subtitle}</Title> : null}
        </Top>

        {categories.length > 1 ? (
          <Filters dial={5} row wrap>
            <Filter
              active={filteredCategory === 'all'}
              category="all"
              title={useVocabularyData('all', languageCode)}
              onClick={() => {
                setFilteredCategory('all')
              }}
            />
            {uniqBy(categories, 'title').map((item, index) => {
              const category = item.title
                .replace(/[^a-zA-Z]/g, '')
                .toLocaleLowerCase()
              return category ? (
                <Filter
                  active={filteredCategory === category}
                  category={category}
                  key={index}
                  onClick={(e) => {
                    setFilteredCategory(e.target.getAttribute('data-category'))
                  }}
                  title={item.title}
                />
              ) : undefined
            })}
          </Filters>
        ) : null}

        <MosaicWrapper>
          <Mosaic>
            {uniqBy(categories, 'title').map((list, index) => {
              const category = list.title
                .replace(/[^a-zA-Z]/g, '')
                .toLocaleLowerCase()
              if (!list.thumbnails) {
                return null
              }
              return (
                <React.Fragment key={index}>
                  <Category>{list.title}</Category>
                  {list.thumbnails.map((item, index) => {
                    counter = counter + 1
                    return (
                      <Thumb
                        className={
                          filteredCategory === category ||
                          filteredCategory === 'all'
                            ? 'visible'
                            : undefined
                        }
                        style={
                          item.width && item.height
                            ? { aspectRatio: '' + item.width / item.height }
                            : undefined
                        }
                        data-counter={counter}
                        key={index}
                        onClick={(e) => {
                          if (!modalGalleryStatus) {
                            setLocked(true)
                            setModalGalleryStatus(true)
                          } else {
                            setLocked(false)
                            setModalGalleryStatus(false)
                          }
                          const realClickedSlide =
                            Number(
                              e.currentTarget.getAttribute('data-counter'),
                            ) - 1
                          setClickedSlide(realClickedSlide)
                          setTimeout(() => {
                            setClickedSlide(null)
                          }, 100)
                        }}
                      >
                        <LazyLoadComponent>
                          <Image {...item} />
                        </LazyLoadComponent>
                      </Thumb>
                    )
                  })}
                </React.Fragment>
              )
            })}
          </Mosaic>
        </MosaicWrapper>

        <Modal className={modalGalleryStatus ? ' open' : undefined}>
          <Close
            languageCode={languageCode}
            onClick={() => {
              setLocked(false)
              setModalGalleryStatus(false)
              setClickedSlide(0)
            }}
          />
          <Gallery
            clickedSlide={clickedSlide}
            images={images}
            isOpen={modalGalleryStatus}
          />
        </Modal>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 8vw 0;

  @media (max-width: 991px) {
    margin: 60px 0;
    margin-top: 120px;
  }
`

const Wrapper = styled.div`
  margin: 0 auto;
`

const Top = styled.div`
  max-width: 41.875rem;
  margin: 0 auto;

  @media (max-width: 1199px) {
    max-width: 21rem;
  }
`

const Heading = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5vw;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 991px) {
    font-size: 28px;
  }
`

const Title = styled.h2`
  margin-top: 1.125rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 19px;
  text-align: center;

  @media (max-width: 991px) {
    margin-top: 20px;
  }
`

const Filters = styled(FlexBox)`
  margin: 4vw 0 2vw 0;

  @media (max-width: 1199px) {
    display: none;
  }
`
const MosaicWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`
const Mosaic = styled.div`
  width: 101%;
  display: flex;
  flex-wrap: wrap;
`

const Category = styled.div`
  position: relative;
  display: none;
  width: 100%;
  margin-top: 3.75rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.subheading};
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 1.125rem;
  text-align: center;
  text-transform: uppercase;
  opacity: 0.7;

  @media (max-width: 1199px) {
    display: block;
    align-items: center;
    font-size: 16px;
    line-height: 1;
    padding: 20px 30px;
    text-align: center;
  }
`

const Thumb = styled.div`
  position: relative;
  display: none;
  width: 19.8vw;
  overflow: hidden;
  cursor: pointer;

  /*
  @media (max-width: 1440px) {
    width: 33.333%;
  }*/

  @media (max-width: 992px) {
    width: 49.9%;
  }

  &:hover {
    img {
      transform: scale(1.07);
    }
  }

  &.visible {
    display: block;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
  }
`

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;

  &:before {
    content: '';
    position: fixed;
    top: 0;
    z-index: 1;
    height: 12.5rem;
    left: 0;
    right: 0;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0.5)},
      ${rgba(theme.colors.variants.neutralDark1, 0)}
    );
  }
  &:after {
    content: '';
    position: fixed;
    bottom: 0;
    z-index: 0;
    height: 12.5rem;
    left: 0;
    right: 0;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0)},
      ${rgba(theme.colors.variants.neutralDark1, 0.5)}
    );
  }

  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }

  .item-close > div {
    color: ${({ theme }) => theme.colors.variants.neutralLight1};
  }
`
